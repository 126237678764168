<template>
  <v-layout column justify-center fill-height class="multiple-choice">
    <!-- Mission question -->
    <div class="multiple-choice__info" v-if="mode != 'social'">
      <ResizableText
        v-if="instructions"
        class="multiple-choice__text"
        :message="instructions"
      />
      <div
        v-if="instructionsPhotoUrl"
        class="multiple-choice__photo"
        :style="`background-image: url('${instructionsPhotoUrl}')`"
      />
    </div>

    <!-- Options -->
    <SlideOpacityTransition>
      <OptionsList :show="showOptions" :options="multipleChoice">
        <template #option="{ option, index }">
          <ChoiceBtn
            :inactive="missionCompleted"
            :wrong="isOptionWrong(index)"
            :correct="isOptionCorrect(index)"
            :selected="isGiven(index)"
            @click="submit(index, option)"
          >
            {{ option }}
          </ChoiceBtn>
        </template>
      </OptionsList>
    </SlideOpacityTransition>

    <!-- Status -->
    <MissionStatus
      v-if="showMissonstatusText"
      :show="showStatus"
      :correct="isMissionSuccess"
      :wrong="isMissionFail"
      class="mb-1"
    >
      <template v-if="!isViewerHostLike && statusText">
        {{ statusText }}
      </template>
    </MissionStatus>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"
import SlideOpacityTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"
import Mode from "@shared/enums/Mode"
import User from "@shared/User"
import Mission from "@shared/Mission"

export default {
  name: "MultipleChoice",
  components: {
    SlideOpacityTransition,
    OptionsList,
    ChoiceBtn,
    ResizableText,
    Ellipsis,
    MissionStatus
  },
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("group", ["isViewerHostLike"]),
    ...mapGetters([
      "missionFail",
      "missionSuccess",
      "missionCompleted",
      "missionAnswers",
      "missionPlaysArray"
    ]),
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters("auth", ["user", "client", "isHost"]),
    isPaused() {
      return (
        this.$store.getters["auth/isHybridRoom"] &&
        Mission.isPausable(this.mission) &&
        this.$store.getters.gameStatus?.paused !== true
      )
    },
    isMissionFail() {
      if (this.isPaused) return false
      return this.missionFail
    },
    instructionsPhotoUrl() {
      return this.mission?.instructionsPhoto
    },
    isMissionSuccess() {
      if (this.isPaused) return false
      return this.missionSuccess
    },
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    getButtonsGridClass() {
      const length = this.multipleChoice.length
      return length > 2 && length !== 4 ? "third" : "half"
    },
    statusText() {
      if (this.mode === Mode.Welcome || this.mode === Mode.Explain) {
        return "Waiting for your turn..."
      }

      if (this.isMissionSuccess && !this.mission.rightWrongIndicationOff) {
        return "CORRECT!"
      }

      if (this.isMissionFail && !this.mission.rightWrongIndicationOff) {
        return "WRONG"
      }

      if (!this.isHost && !this.isScribe && !this.missionCompleted) {
        return "Waiting on Scribe..."
      }

      if (!this.isHost && this.isScribe && !this.missionCompleted) {
        return "You are the Scribe"
      }

      if (this.isHost) {
        return "Answer: " + this.multipleChoice[this.correctAnswerIdx]
      }

      return ""
    },
    showStatus() {
      switch (this.mode) {
        case Mode.Welcome:
        case Mode.Explain:
        case Mode.Play:
        case Mode.Huddle:
          return true

        default:
          return false
      }
    },
    showOptions() {
      return this.mode === Mode.Play || this.mode === Mode.Huddle
    },
    instructions() {
      return this.mission?.instructions
    },
    correctAnswerIdx() {
      return parseInt(this.mission?.multiCorrect) - 1
    },
    multipleChoice() {
      const { mission } = this
      const { answer1, answer2, answer3, answer4, answer5 } = mission
      return [answer1, answer2, answer3, answer4, answer5].filter(Boolean)
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    optionsActive() {
      return this.isScribe && !this.missionCompleted
    },
    showMissonstatusText() {
      return !this.isPresenterUser && !this.mobile
    }
  },
  methods: {
    // if correct
    isCorrect(idx) {
      return this.correctAnswerIdx === idx
    },
    // if is in the state
    isGiven(idx) {
      return this.missionAnswers.indexOf(this.multipleChoice[idx]) > -1
    },
    // show if it's in the state or the mission is over
    isOptionCorrect(idx) {
      if ((this.mission?.dontShowCorrect && !this.isHost) || this.isPaused) {
        return false
      }

      return (
        ((this.isGiven(idx) || this.isHost || this.isPresenterUser) &&
          this.isCorrect(idx)) ||
        (this.missionCompleted && this.isCorrect(idx))
      )
    },
    isOptionWrong(idx) {
      if ((this.mission?.dontShowCorrect && !this.isHost) || this.isPaused) {
        return false
      }

      return (
        (this.isGiven(idx) && !this.isCorrect(idx)) ||
        (this.isGiven(idx) && !this.isCorrect(idx))
      )
    },
    async submit(n) {
      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")

      this.multiAnswer = n
      await this.checkAnswer()
    }
  }
}
</script>

<style lang="scss">
.multiple-choice {
  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__text {
    width: 100%;
    min-height: 50px;
    flex: 1;
  }

  &__photo {
    display: flex;
    flex: 1;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
