var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "multiple-choice",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.mode != "social"
        ? _c(
            "div",
            { staticClass: "multiple-choice__info" },
            [
              _vm.instructions
                ? _c("ResizableText", {
                    staticClass: "multiple-choice__text",
                    attrs: { message: _vm.instructions },
                  })
                : _vm._e(),
              _vm.instructionsPhotoUrl
                ? _c("div", {
                    staticClass: "multiple-choice__photo",
                    style:
                      "background-image: url('" +
                      _vm.instructionsPhotoUrl +
                      "')",
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "SlideOpacityTransition",
        [
          _c("OptionsList", {
            attrs: { show: _vm.showOptions, options: _vm.multipleChoice },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (ref) {
                  var option = ref.option
                  var index = ref.index
                  return [
                    _c(
                      "ChoiceBtn",
                      {
                        attrs: {
                          inactive: _vm.missionCompleted,
                          wrong: _vm.isOptionWrong(index),
                          correct: _vm.isOptionCorrect(index),
                          selected: _vm.isGiven(index),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(index, option)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(option) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.showMissonstatusText
        ? _c(
            "MissionStatus",
            {
              staticClass: "mb-1",
              attrs: {
                show: _vm.showStatus,
                correct: _vm.isMissionSuccess,
                wrong: _vm.isMissionFail,
              },
            },
            [
              !_vm.isViewerHostLike && _vm.statusText
                ? [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }